import React from 'react';
import Layout from "../components/layout"
import ClinicDetailSection from "../components/ClinicDetailSection"
import SingularMapSection from "../components/SingularMapSection"
import ContentContainer from "../components/widgets/ContentContainer"
import HeaderSecton from "../components/widgets/Header"
import { graphql, useStaticQuery } from "gatsby"


export default function ClinicDetailView({clinic}, ...props){

  const communityHeaderData = useStaticQuery(graphql`
  query StaticImageQueryCommunityResource {
      datoCmsSetting {
          communityResourcesHeaderText
          communityResourcesText
          communityResourcesBackgroundImage {
              fluid {
                  base64
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  sizes
              }
          }
      }
  }`)

  const {
    communityResourcesHeaderText,
    communityResourcesText,
    communityResourcesBackgroundImage
  } = communityHeaderData.datoCmsSetting

  if(!clinic){
    return null;
  }

  return(
    <Layout>
      <SingularMapSection
        data={[clinic]}
        state={clinic.state}
      />
      <ClinicDetailSection
        clinic={clinic}
        withButton={!(clinic.contentContainer)}
      />
      {
        clinic.contentContainer && communityResourcesBackgroundImage &&
        <React.Fragment>
          <HeaderSecton
            widget={{
              title: communityResourcesHeaderText,
              text: communityResourcesText,
              headerSize: "Small",
              backgroundImage: {
                fluid: communityResourcesBackgroundImage.fluid
              }
            }}
          />
          <ContentContainer
            widget={clinic.contentContainer}
            withButton={true}
          />
        </React.Fragment>
      }
    </Layout>
  )
}
